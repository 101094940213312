<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
        <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ domain.name }}</h1>
            <!-- <h1 class="text-caption font-weight-light text-center">{{ domain.status }}</h1> -->
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card>
                    <v-toolbar color="teal darken-2" dark flat dense>
                    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                    <v-toolbar-title>Domain</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <!-- <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn icon color="white" @click="sendTestEmail" v-bind="attrs" v-on="on">
                                <font-awesome-icon :icon="['fas', 'paper-plane']"/>
                            </v-btn>
                        </template>
                        <span>Send test email</span>
                    </v-tooltip> -->
                    </v-toolbar>

                    <v-card-text>

                        <p class="text-overline mb-0 mt-2">Domain status</p>
                        <template v-if="domain.status === 'new'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                            Not verified
                        </p>
                        <p class="mb-0 pb-0 text-caption">
                            You must prove ownership of the domain before it can be used with Emplus.
                        </p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Verify domain ownership</router-link>
                        </p>
                        </template>
                        <template v-if="domain.status === 'pending'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                            Verification in progress
                            <!-- TODO: show the verification code here that the user must set in registrar's dns; also maybe a dialog with extra help, or link to public help page -->
                        </p>
                        <p class="mb-0 pb-0 text-caption">
                            You must create a DNS record with this code to prove ownership of the domain.
                            <!-- TODO: link to brandprofile for verification ??? or would that be in the account-verify-domain page ??? or just talk about that process here ??? or in the account-verify-domain page ??? -->
                        </p>
                        </template>
                        <template v-if="domain.status === 'verified'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                            Verified
                        </p>
                        <!-- <p class="mb-0 pb-0 text-caption">
                            You must create a DNS record with this code to prove ownership of the domain.
                        </p> -->
                        </template>

                        <p class="text-overline mb-0 mt-8">Outbound Email Transport</p>
                        <p class="mb-0 pb-0" v-if="!domain.outbound_email_transport_id">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                            Not set
                            <v-btn icon color="teal darken-2" @click="editOutboundEmailTransport">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                            </v-btn>
                        </p>
                        <p class="mb-0 pb-0" v-if="domain.outbound_email_transport_id">
                            <!-- <font-awesome-icon :icon="['fas', 'check']" class="green--text"/> -->
                            <span v-if="outboundEmailTransport">
                                {{ outboundEmailTransport.label }}
                                <v-btn icon color="teal darken-2" @click="editOutboundEmailTransport">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </span>
                            <span v-if="!outboundEmailTransport">
                                Loading...
                            </span>
                        </p>

                        <!-- TODO: rename this section from 'DNS' to "anti-spam" settings for DMARC/DKIM/SPF, and here we can report on status of domain if it's propertly configured for emplus or not... so on server a "check domain" API with items like 'DMARC', 'DKIM', 'SPF', etc. so each item can be checked separately , and we'd call them all from here adn report the result (for each one, checkbox or not) -->

                        <!-- <p class="text-overline mb-0 mt-8">DNS</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-edit-dns', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Edit DNS records</router-link>
                        </p> -->

                        <p class="text-overline mb-0 mt-8">Other</p>
                        <p class="mb-0 pb-0">
                            <!-- TODO: archive/unarchive the domain, so it doesn't even show up for admin unless they ask to look at archived domains -->
                            <!-- <v-btn color="white" class="red--text">Delete Domain</v-btn> -->
                            <router-link :to="{ name: 'account-delete-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">How to delete this domain</router-link>
                        </p>

                        <template v-if="isPermitServiceAdmin">
                        <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                        <p class="mb-0 pb-0">
                            <!-- <router-link :to="{ name: 'service-admin' }">Manage Emplus</router-link> -->
                            <!-- Edit domain status -->
                            <v-btn text color="teal darken-2" class="px-0" @click="editDomainStatus('verified')" v-if="domain.status !== 'verified'">Verify domain</v-btn>
                            <v-btn text color="teal darken-2" class="px-0" @click="editDomainStatus('pending')" v-if="domain.status === 'verified'">Suspend domain</v-btn>
                        </p>
                        </template>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="editOutboundEmailTransportDialog" max-width="600">
                <v-card tile elevation="4" class="pa-0" max-width="600">
                    <v-toolbar short flat color="white">
                        <v-toolbar-title class="green--text">Set the outbound email transport to use with this domain</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="px-5">
                    <v-form class="px-2" @submit.prevent="saveOutboundEmailTransport">
                        <v-row>
                        <v-col>
                            <p>
                                The outbound email transport should be configured with anti-spam settings such as SPF, DKIM, and DMARC for your domain.
                            </p>
                            <v-select outlined dense :items="editableOutboundEmailTransportChoices" v-model="editableOutboundEmailTransportId" color="teal darken-2" label="Outbound email transport" required>
                                <!-- <template #append-outer>
                                    <v-btn icon color="teal darken-2" @click="onClickAddBrandProfile">
                                        <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template> -->
                            </v-select>
                        </v-col>
                        </v-row>
                    </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn elevation="4" class="green white--text" @click="saveOutboundEmailTransport">
                            <span>Save</span>
                        </v-btn>
                        <v-btn text color="grey" @click="editOutboundEmailTransportDialog = false">
                            <span>Cancel</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/domain-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        domain: null,
        error: null,
        whois: null,
        whoisDialog: false,
        nameserverList: null,
        status: null,
        outboundEmailTransport: null,
        editOutboundEmailTransportDialog: false,
        editableOutboundEmailTransportId: null,
        editableOutboundEmailTransportChoices: [],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        registrarName() {
            return this.whois?.info?.Registrar?.join(' / ') ?? 'Unknown';
        },
        registrarURL() {
            try {
                const list = this.whois?.info?.['Registrar URL'];
                if (Array.isArray(list) && list.length > 0) {
                    const inputURL = new URL(list[0]);
                    return inputURL.toString();
                }
                return null;
            } catch (err) {
                return null;
            }
        },
        registrarDnsNameservers() {
            return this.whois?.info?.['Name Server'] ?? [];
        },
        // TODO: move this getter to vuex so we don't have to redefine it everywere... we'll just use mapGetters
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isViewReady() {
            return this.account !== null && this.domain !== null;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name: this.$route.params.domain });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                    if (response.outbound_email_transport_id) {
                        this.loadOutboundEmailTransport(response.outbound_email_transport_id);
                    } else {
                        this.outboundEmailTransport = null;
                    }
                } else {
                    // TODO: redirect back to domain list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async loadOutboundEmailTransport(id) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOutboundEmailTransport: true });
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransport.get(id);
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.outboundEmailTransport = response;
                } else {
                    // TODO: redirect back to domain list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load loadOutboundEmailTransport', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOutboundEmailTransport: false });
            }
        },
        async loadOutboundEmailTransportList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOutboundEmailTransportList: true });
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransport.search(); // optional: { q: 'search term' }
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.editableOutboundEmailTransportChoices = response.list.map((item) => ({
                        value: item.id,
                        text: item.label, // TODO: add type?
                    }));
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load outbound email transport type list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOutboundEmailTransportList: false });
            }
        },
        // async checkWhois() {
        //     try {
        //         this.whois = null;
        //         this.$store.commit('loading', { checkWhois: true });
        //         const response = await this.$client.account(this.$route.params.accountId).domain.checkWhois({ domain: this.$route.params.domain });
        //         console.log(`checkWhois response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.whois = response;
        //         }
        //     } catch (err) {
        //         console.error('failed to check whois', err);
        //     } finally {
        //         this.$store.commit('loading', { checkWhois: false });
        //     }
        // },
        // async checkNameservers() {
        //     try {
        //         this.nameserverList = null;
        //         this.$store.commit('loading', { checkNameservers: true });
        //         const response = await this.$client.account(this.$route.params.accountId).domain.checkNameservers({ domain: this.$route.params.domain });
        //         console.log(`checkNameservers response ${JSON.stringify(response)}`);
        //         if (Array.isArray(response?.list)) {
        //             this.nameserverList = response.list;
        //         }
        //         if (typeof response.status === 'string') {
        //             this.status = response.status;
        //         }
        //     } catch (err) {
        //         console.error('failed to check nameservers', err);
        //     } finally {
        //         this.$store.commit('loading', { checkNameservers: false });
        //     }
        // },
        async editDomainStatus(newValue) {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { editDomainStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).domainStatus.edit({ domain_id: this.domain?.id }, { status: newValue });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.domain, 'status', newValue);
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Domain status updated', message: `New status: ${newValue}` });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update domain status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update domain status' });
            } finally {
                this.$store.commit('loading', { editDomainStatus: false });
            }
        },
        editOutboundEmailTransport() {
            this.editOutboundEmailTransportDialog = true;
            this.editableOutboundEmailTransportId = this.domain.outbound_email_transport_id;
        },
        async saveOutboundEmailTransport() {
            try {
                this.$store.commit('loading', { saveOutboundEmailTransport: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.edit({ id: this.domain?.id }, { outbound_email_transport_id: this.editableOutboundEmailTransportId });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.editOutboundEmailTransportDialog = false;
                    this.$set(this.domain, 'outbound_email_transport_id', this.editableOutboundEmailTransportId);
                    this.loadOutboundEmailTransport(this.editableOutboundEmailTransportId);
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' /* , message: `New status: ${newValue}` */ });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update outbound email transport' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update outbound email transport' });
            } finally {
                this.$store.commit('loading', { saveOutboundEmailTransport: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomain();
        this.loadOutboundEmailTransportList();
        this.checkWhois();
        this.checkNameservers();
    },
};
</script>
